import React from "react"
import styled from "styled-components"
import MenuItems from "./MenuItems"
import Parser from "html-react-parser"

// import app components
import Link from "components/link"

import { useStore } from "store"
import { getUrlPath } from "utils/getUrlPath"

const ProductNavbar = (props) => {
  const { slug } = props

  const [
    {
      appState: { activeSection }
    }
  ] = useStore()

  const urlSection = slug.split("/")[1]

  return (
    <Navigation {...props}>
      <MenuItems
        name={urlSection.includes("architects") ? ["architects"] : urlSection.includes("about") ? ["about"] : ["main"]}
      >
        {(items) => {
          let item = items.find((o) => getUrlPath(o.url).includes(activeSection || urlSection))

          if (!item && items.length > 0) {
            item = items[0]
          }

          let productItems = items.find((o) => o.cssClasses.includes("products"))

          const prdItem = productItems?.childItems?.nodes.find((onavItem) =>
            onavItem?.label?.toLowerCase().includes(activeSection)
          )

          if (prdItem) {
            item = prdItem
          }

          return (
            <MenuContainer>
              {item?.childItems?.nodes
                .filter((o) => !o.cssClasses.includes("section-title"))
                .map((subItem) => (
                  <MenuItem key={subItem.id}>
                    <MenuLink> {Parser(subItem.label)}</MenuLink>
                    {subItem.childItems && (
                      <DropdownMenu>
                        {subItem.childItems.nodes.map((subSubItem) => (
                          <DropdownItem key={subSubItem.id}>
                            <DropdownLink to={getUrlPath(subSubItem.url)}>{Parser(subSubItem.label)}</DropdownLink>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    )}
                  </MenuItem>
                ))}
            </MenuContainer>
          )
        }}
      </MenuItems>
    </Navigation>
  )
}

export default ProductNavbar

// Styled Components

const Navigation = styled.nav`
  display: flex;

  color: #2c312c;
  @media (max-width: 768px) {
    display: none;
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
`

const MenuItem = styled.div`
  position: relative;
  color: white;
  &:hover > div {
    display: block;
  }
  text-transform: uppercase;
  cursor: default;
  &:hover > a {
    border-bottom: 3px solid #00af3f; /* Green border under hovered MenuLink */
  }
`

const MenuLink = styled.div`
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;

  padding-top: 12px;
  padding-bottom: 7px;

  display: inline-block; /* Ensure proper alignment */
  border-bottom: 3px solid transparent; /* Invisible border by default */
  transition: border-bottom 0.3s ease; /* Smooth animation on hover */
  &:hover {
    border-bottom-color: #00af3f; /* Change to green on hover */
  }
`
const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: black;
  color: white;
  min-width: 200px;
  border: 2px solid black;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 5px;
  /* Keep the menu open on hover */
  ${MenuItem}:hover & {
    display: block;
  }
`

const DropdownItem = styled.div`
  padding: 0px 20px;
`

const DropdownLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #00af3f;
  }
`
